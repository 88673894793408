import React from "react";
import { Link } from "react-router-dom";
import "./Rules.scss";
import img1 from "../assets/nyeremenyjatek/png_nyeremeny/1.png";
import img2 from "../assets/nyeremenyjatek/png_nyeremeny/2.png";
import img3 from "../assets/nyeremenyjatek/png_nyeremeny/3.png";
import img4 from "../assets/nyeremenyjatek/png_nyeremeny/4.png";
import img5 from "../assets/nyeremenyjatek/png_nyeremeny/5.png";
import img6 from "../assets/nyeremenyjatek/png_nyeremeny/6.png";
import img7 from "../assets/nyeremenyjatek/png_nyeremeny/7.png";
import img8 from "../assets/nyeremenyjatek/png_nyeremeny/8.png";
import img1_1 from "../assets/nyeremenyjatek/png_nyeremeny/01.jpg";
import img2_2 from "../assets/nyeremenyjatek/png_nyeremeny/02.jpg";
import img3_3 from "../assets/nyeremenyjatek/png_nyeremeny/03.jpg";
import img4_4 from "../assets/nyeremenyjatek/png_nyeremeny/04.jpg";
import img5_5 from "../assets/nyeremenyjatek/png_nyeremeny/05.jpg";
import img6_6 from "../assets/nyeremenyjatek/png_nyeremeny/06.jpg";
import img7_7 from "../assets/nyeremenyjatek/png_nyeremeny/07.jpg";
import img8_8 from "../assets/nyeremenyjatek/png_nyeremeny/08.jpg";

const firstRow = [
  { img: img1, alt: "nyereménykép", bigImg: img1_1 },
  { img: img2, alt: "nyereménykép", bigImg: img2_2 },
  { img: img3, alt: "nyereménykép", bigImg: img3_3 },
  { img: img4, alt: "nyereménykép", bigImg: img4_4 },
  { img: img5, alt: "nyereménykép", bigImg: img5_5 },
  { img: img6, alt: "nyereménykép", bigImg: img6_6 },
  { img: img7, alt: "nyereménykép", bigImg: img7_7 },
  { img: img8, alt: "nyereménykép", bigImg: img8_8 },
];

const awards = () => {
  return (
    <div className="bg">
      <div className="imgHolder">
        {" "}
        <Link to="/">
          <button on className="rules-button">
            Vissza
          </button>
        </Link>
        <h2>NYEREMÉNYEK</h2>
        <div className="prize-images">
          {firstRow.map((img) => (
            <a href={img.bigImg} target="_blank">
              <img src={img.img} alt={img.alt} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default awards;
