import React from "react";
import "./Dashboard.scss";
import RightSide from "./RightSide";
import LeftSide from "./LeftSide";

const Dashboard = () => {
  return (
    <div className="main">
      <LeftSide />
      <RightSide />
    </div>
  );
};

export default Dashboard;
