import React from "react";
import "./Rules.scss";
import { Link } from "react-router-dom";

const winnerCodes = {
  card: ["H00211520211027183847"],
  television: ["H00219920211018120518"],
  roller: ["H00212920211006144153"],
  photo: [
    "H00217220211002135926",
    "H00214220211019095256",
    "H00215920211027103604",
    "H00217420211013172335",
  ],
  watch: [
    "H00218820211023175943",
    "H00219720211025173518",
    "H00211020211015132513",
    "H00212320211006171617",
    "H00212420211004123441",
    "H00213920211007100338",
    "H00213220211028120653",
    "H00110020211011105300",
    "H00212620211027171950",
    "H00110720211012104631",
  ],
  media: [
    "H00213220211014203511",
    "H00218820211101121518",
    "H00216120211018134746",
    "H00214620211018215209",
    "H00216120211002121446",
    "H00214620211002131624",
    "H00215620211023193722",
    "H00214720211007104556",
    "H00214520211001180444",
    "H00210820211001172500",
    "H00116220211019175443",
    "H00118920211006112815",
    "H00210120211003174140",
    "H00210820211003200212",
    "H00210420211023184630",
    "H00112120211001165853",
    "H00215720211021134745",
    "H00214220211029104326",
    "H00113520211026161945",
    "H00218320211001182755",
  ],
  coupon: [
    "H00212720211026125454",
    "H00113720211025184139",
    "H00210620211015172536",
    "H00211920211018210055",
    "H00212320211009212039",
    "H00211720211007135158",
    "H00211120211015193151",
    "H00211820211023123557",
    "H00216220211010125105",
    "H00212420211009105707",
    "H00211620211002192623",
    "H00212120211017191711",
    "H00211720211028192505",
    "H00210420211101101318",
    "H00115320211002170053",
    "H00212420211028095254",
    "H00212920211029144137",
    "H00212320211009193955",
    "H00213420211019112347",
    "H00114120211011112907",
    "H00210420211005110400",
    "H00212920211012111602",
    "H00212920211024120103",
    "H00210620211019114902",
    "E00211920211009210612",
    "H00112020211026183236",
    "H00112920211012105913",
    "H00110820211026172147",
    "H00216020211020215259",
    "H00115720211004105827",
    "H00215620211004122301",
    "H00113220211004195355",
    "H00215820211008193452",
    "H00210420211001092932",
    "H00215720211021171012",
    "H00112720211006174530",
    "H00218420211020155226",
    "H00215220211022164735",
    "H00119720211026132047",
    "H00212720211022190650",
    "H00213620211029182223",
    "H00212920211030185256",
    "H00211320211017200610",
    "H00210020211018112300",
    "H00216520211010181928",
    "H00115120211006210444",
    "H00213920211027164028",
    "H00211720211022183946",
    "H00215420211017161632",
    "H00213520211008095037",
    "H00210720211015173141",
    "H00216620211005181418",
    "H00214120211025124749",
    "H00215120211010171108",
    "H00211920211006103612",
    "H00212020211004123824",
    "H00215020211007120112",
    "H00211620211017115615",
    "H00216020211023163705",
    "H00214320211002124413",
  ],
};

const winners = () => {
  const Section = (title, items) => {
    return (
      <div>
        <h3>{title}</h3>
        <ul>
          {items.map((code) => (
            <li key={code}>{code}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Link to="/">
        <button>Vissza</button>
      </Link>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>{Section("Fődíj", winnerCodes.card)}</div>
        <div>{Section("Televízió", winnerCodes.television)}</div>
        <div>{Section("Roller", winnerCodes.roller)}</div>
        <div>{Section("Fényképező", winnerCodes.photo)}</div>
        <div>{Section("Okos óra", winnerCodes.watch)}</div>
        <div>{Section("Media Markt utalvány", winnerCodes.media)}</div>
        <div>{Section("Pizza Sprint ajándék kártya", winnerCodes.coupon)}</div>
      </div>
    </div>
  );
};

export default winners;
