import React from 'react';
import './RightSide.scss';
import { NewSubmitForm } from './NewSubmitForm';
import Nav from './Nav';
import Buttons from './Buttons';

const RightSide = () => {
  return (
    <div className='holder'>
      <Nav />
      <h2 style={{ color: 'white' }}>A nyereményjáték véget ért!</h2>
      <Buttons />
    </div>
  );
};

export default RightSide;
