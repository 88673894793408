import React from "react";
import "./Buttons.scss";

const Buttons = () => {
  return (
    <div className="bottom">
      <a href="/Adatvédelem.pdf" target="_blank"><button className="bottom-buttons">Adatvédelem</button></a>
      <a href="/Játékszabályzat.pdf" target="_blank"><button className="bottom-buttons">Játékszabály</button></a>
      <a href="/Gyik.pdf" target="_blank"><button className="bottom-buttons">GYIK</button></a>
      <a href="/Kapcsolat.pdf" target="_blank"> <button className="bottom-buttons">Kapcsolat</button></a>
    </div>
  );
};

export default Buttons;
