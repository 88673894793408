import React, { useState, useEffect } from 'react';
import { app } from '../firebase/base';
import './Admin.scss';
import Logo from '../assets/logo.png';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Admin = () => {
  const [auth, setAuth] = useState(false);
  const [vis, setVis] = useState(false);
  const [open, setOpen] = useState(false);
  const [submits, setSubmits] = useState([]);
  const [input, setInput] = useState(1);
  const [winners, setWinners] = useState();
  const [tempwinners, setTempwinners] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const db = app.firestore();
  console.log(submits);

  const onSubmit = (e) => {
    e.preventDefault();
    if (email !== 'admin' && password !== 'admin') {
      return alert('hiba');
    }

    setAuth(true);
  };

  const urll =
    'https://firebasestorage.googleapis.com/v0/b/upload-images-37d27.appspot.com/o/image.jpg?alt=media&token=59ee5d45-7df2-4fa8-aa4e-555daed27667';
  useEffect(() => {
    const unmount = db.collection('albums').onSnapshot((snapshot) => {
      const tempAlbums = [];
      snapshot.forEach((doc) => {
        tempAlbums.push({ ...doc.data(), id: doc.id });
      });
      setSubmits(tempAlbums);
    });
    return unmount;
  }, []);

  const shuffle = (number) => {
    setVis(true);
    setTimeout(() => {
      const tempArray = [...submits];
      const winners = tempArray.sort(() => Math.random() - 0.5);
      const tempWinners = [...winners];

      setWinners(winners.slice(0, number));
      setTempwinners(tempWinners.slice(-(number * 3)));

      setVis(false);
    }, 1000);
  };
  if (!auth)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
          backgroundColor: '#d8232a',
        }}
      >
        <form
          onSubmit={(e) => onSubmit(e)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '300px',
          }}
        >
          <input
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Felhasználó'
            className='inputs'
          ></input>
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Jelszó'
            className='inputs'
          ></input>
          <button type='submit' className='loginButton'>
            Bejelentkezés
          </button>
        </form>
      </div>
    );

  const Modal = () => {
    return (
      <div className='overlay'>
        <button className='onclose' onClick={() => setOpen(!open)}>
          Bezárás
        </button>
        <div className='modal'>
          <div>
            <img style={{ height: '150px', width: '150px' }} src={Logo}></img>
            <div className='shf'>
              <label for='input' style={{ fontSize: '35px' }}>
                Nyertesek száma
              </label>
              <input
                id='input'
                value={input}
                style={{ width: '30px' }}
                onChange={(e) => setInput(e.target.value)}
                placeHolder=''
                className='shufflenumber'
              />
              <button className='shufflebtn' onClick={() => shuffle(input)}>
                Sorsolás
              </button>
            </div>
            {vis && (
              <div>
                <Loader
                  type='MutatingDots'
                  color='red'
                  height={100}
                  width={100}
                />
                <h2>Sorsolás..</h2>
              </div>
            )}
            {winners && (
              <>
                <div className='xd'>
                  <h1>Nyertesek</h1>
                  <h1>Tartalék Nyertesek</h1>
                </div>
                <div className='xdd'>
                  <table border='1' cellPadding='20' className='css-serial'>
                    <tbody>
                      <tr>
                        <th>Sorszám</th>
                        <th>Kód</th>
                      </tr>
                      {winners.map((data) => (
                        <tr key={data.code}>
                          <td></td>
                          <td>{data.code}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>{' '}
                  <table border='1' cellPadding='10' className='css-serial'>
                    <tbody>
                      <tr>
                        <th style={{ width: '10px' }}>Sorszám</th>
                        <th>Kód</th>
                      </tr>
                      {tempwinners.map((data) => (
                        <tr key={data.code}>
                          <td></td>
                          <td>{data.code}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>{' '}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='admin'>
      <table border='1' cellPadding='20' className='css-serial'>
        <tbody>
          <tr>
            <th>Sorszám</th>
            <th>Név</th>
            <th>Kód</th>
            <th>Email</th>
            <th>Telefonszám</th>
            <th>Hírlevél</th>
          </tr>
          {submits.map((data) => (
            <tr key={data.code}>
              <td></td>
              <td>{data.name}</td>
              <td>{data.code}</td>
              <td>{data.email}</td>
              <td>06{data.phone}</td>
              <td>
                <input type='checkbox' disabled checked={data.email}></input>
              </td>
            </tr>
          ))}
        </tbody>
      </table>{' '}
      <button className='openmodal' onClick={() => setOpen(!open)}>
        Sorsolás
      </button>
      {open && <Modal />}
    </div>
  );
};
export default Admin;
