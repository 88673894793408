import React from "react";
import { Link } from "react-router-dom";
import "./Rules.scss";
import img1 from "../assets/nyeremenyjatek/png/01.png";
import img2 from "../assets/nyeremenyjatek/png/02.png";
import img3 from "../assets/nyeremenyjatek/png/03.png";
import img4 from "../assets/nyeremenyjatek/png/04.png";
import img5 from "../assets/nyeremenyjatek/png/05.png";
import img6 from "../assets/nyeremenyjatek/png/06.png";
import img7 from "../assets/nyeremenyjatek/png/07.png";

const firstRow = [
  { img: img1, alt: "nyereménykép" },
  { img: img2, alt: "nyereménykép" },
  { img: img3, alt: "nyereménykép" },
  { img: img4, alt: "nyereménykép" },
];

const secondRow = [
  { img: img5, alt: "nyereménykép" },
  { img: img6, alt: "nyereménykép" },
  { img: img7, alt: "nyereménykép" },
];
const rules = () => {
  return (
    <div className="bg">
      <div className="imgHolder">
        {" "}
        <Link to="/">
          <button on className="rules-button">
            Vissza
          </button>
        </Link>
        <h2>HOGYAN SZÁLLJ VERSENYBE A PIZZA SPRINT NYEREMÉNYEKÉRT?</h2>
        <div className="images">
          {firstRow.map((img) => (
            <img src={img.img} alt={img.alt} />
          ))}
        </div>
        <div className="divider"></div>
        <h2>
          AZ EXTRA PIZZA UTALVÁNY MEGSZERZÉSÉRT JÁTSSZ VELÜNK AZ INSTÁN IS!
        </h2>
        <div className="images">
          {secondRow.map((img) => (
            <img src={img.img} alt={img.alt} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default rules;
