import React from "react";
import Admin from "./components/Admin";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import rules from "./components/rules";
import awards from "./components/awards";
import winners from "./components/winners";

function App() {
  return (
    <div style={{ display: "flex" }}>
      <Router>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/admin" exact component={Admin} />
          <Route path="/rules" exact component={rules} />
          <Route path="/prizes" exact component={awards} />
          <Route path="/winners" exact component={winners} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
