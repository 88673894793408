import React, { useState } from 'react';
import { app } from '../firebase/base';
import './Form.scss';
import { FcInfo } from 'react-icons/fc';
import Modal from './Modal';

const db = app.firestore();

export const NewSubmitForm = () => {
  const initialState = {
    name: '',
    phone: '',
    email: '',
    code: '',
  };

  const initialChecks = {
    first: false,
    second: false,
    third: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [success, setSuccess] = useState(false);
  const [checks, setChecks] = useState(initialChecks);
  const [open, setOpen] = useState(false);

  const onAlbumCreate = () => {
    return db.collection('albums').doc(formData.code).set(formData);
  };

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDocRef = db.collection('albums').doc(formData.code);
    const doc = await userDocRef.get();
    if (!doc.exists) {
      onAlbumCreate()
        .then(() => setSuccess(true))
        .catch((err) => alert('Hiba történt!'));
    } else {
      alert('Ez a promócios kód már fel lett használva korábban!');
    }
  };

  const isSubmitVisible =
    formData.name !== '' &&
    formData.email !== '' &&
    formData.code.length === 14 &&
    checks.first &&
    checks.second;

  if (success)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <h1>Sikeres feltöltés!</h1>
        <button className='nav-button' onClick={() => window.location.reload()}>
          Új feltöltés
        </button>
      </div>
    );

  return (
    <div>
      <div className='form'>
        <Modal open={open} setOpen={setOpen} />
        <div>
          <form className='submitForm' onSubmit={(e) => handleSubmit(e)}>
            <input
              name='name'
              className='inputs'
              value={formData.name}
              onChange={handleChange}
              required
              type='input'
              placeholder='Teljes név*'
            />
            <input
              name='email'
              className='inputs'
              value={formData.email}
              onChange={handleChange}
              type='email'
              required
              placeholder='Érvényes email cím*'
            />
            <div>
              <span className='number'>06</span>
              <input
                name='phone'
                className='inputs-phone'
                maxLength='9'
                value={formData.phone}
                onChange={handleChange}
                type='phone'
                placeholder='Telefonos elérhetőség'
              />
            </div>

            <div>
              {' '}
              <input
                name='code'
                className='inputs'
                value={formData.code}
                onChange={handleChange}
                required
                type='input'
                maxLength={14}
                placeholder='Promóciós kód*'
              />
              <span
                onClick={() => setOpen(true)}
                style={{
                  paddingLeft: '5px',
                  fontSize: '25px',
                  textAlign: 'top',
                  cursor: 'pointer',
                }}
              >
                <FcInfo />
              </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input
                type='checkbox'
                className='checkinput'
                placeholder='x'
                onChange={() => setChecks({ ...checks, first: !checks.first })}
              />

              <span className='checkboxlabel'>
                * Elolvastam és elfogadom a{' '}
                <a href='/Játékszabályzat.pdf'>Játékszabály</a>-ban foglaltakat!
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input
                className='checkinput'
                type='checkbox'
                placeholder='x'
                onChange={() =>
                  setChecks({ ...checks, second: !checks.second })
                }
              />

              <span className='checkboxlabel'>
                * Elolvastam és elfogadom az{' '}
                <a href='/Adatvédelem.pdf'>Adatkezelési Tájékoztató</a>-ban
                foglaltakat!
              </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input
                className='checkinput'
                type='checkbox'
                placeholder='x'
                onChange={() => setChecks({ ...checks, third: !checks.third })}
              />

              <span className='checkboxlabel'>
                Feliratkozom a hírlevélre!
                <br />
              </span>
            </div>
            {isSubmitVisible ? (
              <button style={{ cursor: 'pointer' }} type='submit'>
                Beküldés
              </button>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
