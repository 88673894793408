import React from 'react';
import './Nav.scss';
import { Link } from 'react-router-dom';
import Logo from '../assets/max/ps_max_slogan.svg';
import Bg from '../assets/bg25.jpg';

const Nav = () => {
  return (
    <div className='nav-holder'>
      <div className='nav-image'>
        <img src={Logo} alt='logo'></img>
      </div>
    </div>
  );
};

export default Nav;
