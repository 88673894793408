import firebase from "firebase";
import "firebase/storage";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyAAyynUILKx9r5Vt0qnRQEUOjz0mjHflxY",
  authDomain: "upload-images-37d27.firebaseapp.com",
  projectId: "upload-images-37d27",
  storageBucket: "upload-images-37d27.appspot.com",
  messagingSenderId: "129462196405",
  appId: "1:129462196405:web:107f070fa3eb27cbdac6fc",
});
